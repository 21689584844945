.x-hidden-scroll {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  -ms-overflow-style: none;
}

.x-hidden-scroll::-webkit-scrollbar {
  display: none;
}

.mx-w-400 {
  max-width: 400px;
}